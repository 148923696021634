<template>
    <div>
        <p v-if="isPassKeys">Passkeys is not registered for your account. Follow on screen steps to register a passkey .</p>
        <p v-else>TOTP Token is not registered for your account. Scan QR Code in any OTP authenticator app like Microsoft/Google authenticator and verify your token.</p>
    
        <div class="form-group row pt-2" v-if="!isPassKeys">
            <label class="control-label" style="font-size:small">Scan QR Code</label>
            <div class="col text-center" style="min-height:250px">
                <img :src="qrCode" alt="QR Code" width="250" class="img">
            </div>
        </div>

        <div class="mb-2 mt-2">
            <label class="form-label" for="username">{{$t('Username')}}</label>
            <input type="text" name="username" v-model="props.state.username" id="username" disabled class="form-control"  />
        </div>
        <div class="mt-2" v-if="!isPassKeys">
            <label class="form-label" for="token">{{$t('Token')}}</label>
            <input type="text" name="token" v-model="otpToken" id="token" class="form-control" :class="{ 'is-invalid' : tokenError != null }" autocomplete="off" required>
            <span class="invalid-feedback mt-0" v-if="tokenError">{{ tokenError }}</span>
        </div>

        <ErrorComponent v-if="errors" :errors="errors" :capsLockOn="capsLockOn" :key="state.updated"/> 
      
        <div class="form-group mb-2">
            <div class="col">
                <button type="button" v-if="isPassKeys" class="btn btn-o365-login mt-2" @click="registerPasskey">Register</button>
                <button type="button" v-else class="btn btn-o365-login mt-2" @click="sendOTP">Verify one-time password</button>
            </div>            
        </div>  
        <div class="d-flex justify-content-between align-items-center my-2">
            <a type="button" @click="resetState">Back</a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, onMounted, inject } from 'vue';
    import { createRequest, coerceToBase64Url, coerceToArrayBuffer } from 'o365.modules.Login.shared.js';
    import ErrorComponent from 'o365.vue.components.Login.Errors.vue';

    const updateState = inject('updateState') as Function;
    const authenticated = inject('authenticated') as Function;
    const isBusy = inject('isBusy') as Function;
    const setErrors = inject('setErrors') as Function;
    const props = defineProps({
        state: { type: Object, required: true },
        errors: { type: Object, required: false, default: {} }, 
        capsLockOn: { type: Boolean, required: false, default: false }
    });

    const isPassKeys = computed(() => props.state.currentProvider == 'fido2');

    const isLoaded = ref(false);
    const qrCode = ref('data:image/jpeg;base64, null');
    const token = ref('');
    const tokenError = ref<string|null>(null);
    const otpToken = computed({
        get() { return token.value },
        set(newValue) {
            token.value = newValue;
            tokenError.value = null;
        }
    })

    async function registerPasskey(){
        try {
            isBusy(true);
            let makeCredentialOptions = await getCredentialOptionsAsync();
            if (makeCredentialOptions.status !== "ok") {
                return setErrors(makeCredentialOptions.errorMessage);
                // return;
            }
            makeCredentialOptions.challenge = coerceToArrayBuffer(makeCredentialOptions.challenge);
            makeCredentialOptions.user.id = coerceToArrayBuffer(makeCredentialOptions.user.id);
            makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c) => {
                c.id = coerceToArrayBuffer(c.id);
                return c;
            });

            if (makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null) {
                makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;
            }

            let newCredential = await navigator.credentials.create({
                publicKey: makeCredentialOptions
            });

            await verifyRegister(newCredential);

        } catch (e) {
            console.warn(e);
            setErrors(e.message);
        } finally {
            isBusy(false);
        }
    }

    async function verifyRegister(assertedCredential) {
        let attestationObject = new Uint8Array(assertedCredential.response.attestationObject);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        const attestation = {
            id: assertedCredential.id,
            rawId: coerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
                AttestationObject: coerceToBase64Url(attestationObject),
                clientDataJSON: coerceToBase64Url(clientDataJSON),
                transports: assertedCredential.response.getTransports()
            }
        };

        const data = {
            attestation: attestation,
            token: token.value
        };

        let response;
        try {
            let res = await createRequest("/nt/api/fido2/register", data);
            response = await res.json();
        } catch (e) {
            setErrors(e.message);
            return;
        }

        // show error
        if (response.status !== "ok") {
            setErrors(response.errorMessage);
            return;
        } else{
            authenticated();
        }
    }

    async function getCredentialOptionsAsync(){
        try {
            var res = await createRequest('/nt/api/fido2/registerOptions', { username: props.state.username });
            return await res.json();
        } catch (e) {
            props.state.errors.value['error'] = ("Request to server failed: " + e.message);
        }
    }

    async function getState()
    {
        try {
            const response = await createRequest('/api/login/mfa', {});
            if(response.ok){
                let json = await response.json();
                qrCode.value = 'data:image/jpeg;base64,' + json.qrCodeBase64String;
            }
        } catch(e){
            props.state.multiFactorState = 0;
        }
    }

    async function sendOTP(){
        if(token.value?.length < 6){
            tokenError.value = 'Token is too short. Min length should be at least 6 symbols!';
            return;
        }
        isBusy(true);
        var data = { token: token.value };
        const response = await createRequest('/api/login/mfa', data);
        isBusy(false);
        if(response.ok){
            var json = await response.json();
            if(json.multiFactorState === 3){
                authenticated();
            }
        } else if(response.status == 400){
            var json = await response.json();
            if(json.errors?.Token){
                tokenError.value = json.errors.Token;
            } else {
                setErrors(json.errors);
            }
        }        
    }

    async function resetState(){
        if(props.state.action != 'login' || props.state.multiFactorState == 1){
            var resp = await createRequest("/api/login/mfa", { back: true });
            if(resp.ok){
                var json = await resp.json();
                updateState(json);
            }
        }
        updateState();
        setErrors({});
    }

    onMounted(async () => {
        console.log("Mounted");
        if(!isPassKeys.value){
            await getState();
        }
        isLoaded.value = true;
        isBusy(false);
    });

</script>